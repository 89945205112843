
import ConfigUpdate from "./variants/ConfigUpdate";
// import Basic from "./variants/Basic";
// import CustomTrigger from "./variants/CustomTrigger";
// import FloatingTrigger from "./variants/FloatingTrigger";
// import FloatingTriggerWithCustomText from "./variants/FloatingTriggerWithCustomText";

function App() {
  return (
    <div>
      {/* <Basic /> */}
      {/* <CustomTrigger /> */}
      <ConfigUpdate />
      {/* <FloatingTrigger /> */}
      {/* <FloatingTriggerWithCustomText />  */}

    </div>
  );
}

export default App;
